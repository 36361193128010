import Signup from "../features/auth/components/Signup";

function SignupPage() {
    return ( 
        <div>
            <Signup></Signup>
        </div>
     );
}

export default SignupPage;